export const RMMENUS = [
	{ page: "-", header: "Stats" },
	{ page: "/UsersPage", title: "Users", code: "users", admintype: false, iconClass: "users" },
	{ page: "/GroupPage", title: "Institutes", code: "groups", admintype: false, iconClass: "building" },
	{ page: "/ProjectsPage", title: "Cases", code: "projects", admintype: false, iconClass: "folders" },

	//{ page: "-", header : "Product" },

	{ page: "-", header: "Payments/Subscriptions", onlyray: true },
	{ page: "/RTProduct", title: "Product(Beta)", code: "product", admintype: false, iconClass: "box", onlyray: true },
	{ page: "/Subscriptions", title: "Subscriptions", code: "subscriptions", admintype: false, iconClass: "hand-click", onlyray: true },
	{ page: "/Payments", title: "Payments", code: "payments", admintype: false, iconClass: "credit-card", onlyray: true },
	//{ page: "/PaymentGateways", title: "Payment Gateway", code: "paymentgateway", admintype: true, iconClass: "route-alt-left"},
	// { page: "-", header : "Notification" },
	// { page: "/NotifyEmail", title: "Email", code: "notifyemail", admintype: false },

	{ page: "-", header: "Contents" },
	{ page: "/BoardPage", title: "CMS", code: "contentsmanager", admintype: false, iconClass: "notes" },
	{ page: "/MailingPage", title: "Mailing", code: "mailing", admintype: false, iconClass: "mail" },
	{ page: "/Notifications", title: "Notifications", code: "notifications", admintype: false, iconClass: "bell" },
	//{ page: "/SupportPage", title: "Support", code: "supportsmanager", admintype: false, iconClass: "help" },
	//{ page: "/FeedBacksPage", title: "User FeedBacks", code: "feedbacks", admintype: false, iconClass: "message-chatbot" },

	//{ page: "-", header : "Configurations" },
	//{ page: "/ProductPage", title: "RAY Product", code: "labproduct", admintype: false, iconClass: "ti ti-package"},

	{ page: "-", header: "ETC", onlyray: true },
	{ page: "/Applications", title: "Softwares", code: "softwares", admintype: false, iconClass: "code-circle", onlyray: true },
	{ page: "/Stats", title: "Statistics", code: "stats", admintype: false, iconClass: "chart-bar", showall: true, onlyray: true },
	{ page: "/ManagerGroup", title: "Manager Group", code: "managergroups", admintype: false, iconClass: "users-group", onlyray: true },
	{ page: "/ManagerFeedbacks", title: "Feedback", code: "feedback", admintype: false, iconClass: "message", onlyray: true },
	{ page: "/Devices", title: "Devices", code: "devices", admintype: false, iconClass: "device-desktop", onlyray: true },
	{ page: "/Rayteams", title: "RAYTeams Client", code: "rayteams", admintype: false, iconClass: "checkup-list", onlyray: true },
	{ page: "/LicensePage", title: "Licenses", code: "licenses", admintype: true, iconClass: "license" },
	{ page: "/Partners", title: "B2B", code: "partners", admintype: false, iconClass: "chart-circles", onlyray: true },
	//{ page: "/Codes", title: "Codes", code: "code", admintype: false, iconClass: "help", showall : true },

	{ page: "-", header: "Management" },
	{ page: "/LabProductPage", title: "Product", code: "b2bproduct", admintype: false, showall: true, iconClass: "ti ti-package" },
	{ page: "/LabProductCategoryPage", title: "Product Category", code: "b2bproductcategory", admintype: false, showall: true, iconClass: "ti ti-package" },
	{ page: "/Forms", title: "Forms", code: "form", showall: true, admintype: false, iconClass: "clipboard-text" },
	{ page: "/WorkflowPage", title: "Workflow", code: "workflow", admintype: false, iconClass: "brand-stackshare" },
	{ page: "/SiteConfigPage", title: "Configuration", code: "conf", admintype: true, iconClass: "settings", onlb2b: true },
	{ page: "/AdminUserPage", title: "Accounts", code: "account", admintype: true, iconClass: "user-shield" },
	{ page: "/security", title: "Security", code: "security", admintype: true, iconClass: "lock" },
	{ page: "/Logs", title: "Logs", code: "audit", admintype: true, iconClass: "list-search" },
	//{ page: "/Lang", title: "Language", code: "lang", admintype: false, iconClass: "help", showall : true },
	//{ page: "/Setting", title: "Setting", code: "settings", admintype: false, setting: true },
	//{ page: "/BoardPage", title: "Content Manager", code: "contentsmanager", admintype: false },
	//{ page: "/UsagePage", title: "Usage", code: "usage", admintype: trye },
]
export const RMBTNS = [
	{ scope: "users", code: "user-exportuserdata", title: "", color: "btn-primary", comment: "사용자 데이터를 pdf로 내보냅니다." },
	{ scope: "users", code: "user-approvedaccount", title: "To Approved", color: "btn-primary", comment: "계정을 승인합니다." },
	{ scope: "product", code: "rtp-pricemodify", title: "Modify", color: "btn-secondary", comment: "Price 정보를 수정 합니다." },
	{ scope: "product", code: "rtp-pricepublish", title: "Publish", color: "btn-success", comment: "Price 정보를 PG 사에 등록 합니다." },
	{ scope: "payments", code: "paytn-", title: "Delete", color: "btn-danger", comment: "파트너에 소유권을 해지합니다." },
	{ scope: "contentsmanager", code: "cm-addpool", title: "Add pool", color: "btn-primary", comment: "Pool을 생성합니다." },
	{ scope: "contentsmanager", code: "cm-editalias", title: "Edit Alias", color: "btn-info", comment: "Alias를 편집합니다." },
	{ scope: "contentsmanager", code: "cm-adduc", title: "Add User Content", color: "btn-primary", comment: "User Content를 등록합니다." },
	{ scope: "contentsmanager", code: "cm-createmenu", title: "Create Menu", color: "btn-primary", comment: "메뉴를 생성합니다." },
	{ scope: "contentsmanager", code: "cm-edit", title: "Edit", color: "btn-info", comment: "글을 수정합니다." },
	{ scope: "contentsmanager", code: "cm-del", title: "Delete", color: "btn-danger", comment: "글을 삭제합니다." },
	{ scope: "mailing", code: "mail-sendnew", title: "Send new email", color: "btn-primary", comment: "새 메일을 작성합니다." },
	{ scope: "notifications", code: "notify-edit", title: "Edit", color: "btn-primary", comment: "알림 내용을 수정합니다." },
	{ scope: "softwares", code: "sw-customizedconfig", title: "Create customized config", color: "btn-primary", comment: "사용자 정의 구성 요소를 생성합니다." },
	{ scope: "managergroups", code: "mgr-addgroup", title: "Add Manager Group", color: "btn-primary", comment: "Manager Group을 등록합니다." },
	{ scope: "managergroups", code: "mgr-addlicense", title: "Add Group License", color: "btn-primary", comment: "Manager Group에 License를 부여합니다." },
	// { scope: "managergroups", code: "mgr-editgroup", title: "Edit", color: "btn-secondary", comment: "Manager Group을 수정합니다." },
	{ scope: "feedback", code: "fb-add", title: "Add", color: "btn-primary", comment: "피드백을 새로 추가합니다." },
	{ scope: "feedback", code: "fb-update", title: "Update", color: "btn-primary", comment: "피드백을 수정합니다." },
	{ scope: "feedback", code: "fb-remove", title: "Remove", color: "btn-danger", comment: "피드백을 삭제합니다." },
	{ scope: "licenses", code: "lic-addmanapp", title: "Add Managed App", color: "btn-primary", comment: "Managed App을 등록합니다.", onlyray: true },
	{ scope: "licenses", code: "lic-addpool", title: "Add License", color: "btn-primary", comment: "License를 Pool에 등록합니다.", onlyray: true },
	{ scope: "licenses", code: "lic-assignlc", title: "License 부여", color: "btn-primary", comment: "사용자에게 License를 부여합니다." },
	{ scope: "licenses", code: "lic-dellc", title: "License 회수", color: "btn-danger", comment: "사용자에게 부여된 License를 회수합니다.", onlyray: true },
	{ scope: "partners", code: "ptn-addpartner", title: "Add a partner", color: "btn-primary", comment: "새로운 파트너를 등록합니다." },
	{ scope: "partners", code: "ptn-editpartner", title: "Edit", color: "btn-secondary", comment: "파트너 정보를 변경합니다." },
	{ scope: "partners", code: "ptn-showdevice", title: "Show B2B Devices", color: "btn-primary", comment: "파트너에게 전달된 장비의 목록을 열람합니다." },
	{ scope: "partners", code: "ptn-adddevice", title: "Add Devices", color: "btn-primary", comment: "파트너에 장비의 소유권을 부여합니다." },
	{ scope: "partners", code: "ptn-delsn", title: "Delete", color: "btn-danger", comment: "파트너에 소유권을 해지합니다." },
	{ scope: "partners", code: "ptn-basicedit", title: "Edit Info", color: "btn-primary", comment: "파트너의 기본 정보를 수정합니다." },
	{ scope: "partners", code: "ptn-email", title: "Edit Email", color: "btn-primary", comment: "파트너의 이메일 정보를 수정합니다." },
	{ scope: "partners", code: "ptn-uc", title: "Edit User Contents", color: "btn-primary", comment: "파트너의 사용자 동의를 수정합니다." },
	{ scope: "partners", code: "ptn-codes", title: "Add a Code", color: "btn-primary", comment: "파트너의 코드 정보를 추가합니다." },
	{ scope: "b2bproduct", code: "prod-add", title: "Add Product", color: "btn-primary", comment: "Product를 추가합니다." },
	{ scope: "b2bproduct", code: "prod-infoedit", title: "Edit", color: "btn-primary", comment: "Product 기본 정보를 수정합니다." },
	{ scope: "b2bproduct", code: "prod-specedit", title: "Edit", color: "btn-primary", comment: "Product 스펙 정보를 수정합니다." },
	{ scope: "b2bproduct", code: "prod-addform", title: "Add Product Form", color: "btn-primary", comment: "주문서 양식을 추가합니다." },
	{ scope: "b2bproduct", code: "prod-cancelform", title: "Cancel", color: "btn-danger", comment: "주문서 양식을 취소합니다." },
	{ scope: "b2bproduct", code: "prod-addconsent", title: "Add Consent", color: "btn-primary", comment: "동의서를 추가합니다." },
	{ scope: "b2bproduct", code: "prod-cancelconsent", title: "Cancel", color: "btn-danger", comment: "동의서를 취소합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-add", title: "Add Product Category", color: "btn-primary", comment: "Product 카테고리를 추가합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-infoedit", title: "Edit", color: "btn-warning", comment: "Product 카테고리 정보를 수정합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-addprod", title: "Add Product", color: "btn-primary", comment: "상품을 추가합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-addopt", title: "Add Product Option", color: "btn-primary", comment: "상품 옵션을 추가합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-delopt", title: "Delete", color: "btn-danger", comment: "상품 옵션을 삭제합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-addspec", title: "Add Product Spec", color: "btn-primary", comment: "상품 스펙을 추가합니다." },
	{ scope: "b2bproductcategory", code: "prodcate-delspec", title: "Delete", color: "btn-danger", comment: "상품 스펙을 삭제합니다." },
	{ scope: "form", code: "form-add", title: "Add Form", color: "btn-primary", comment: "폼을 등록합니다." },
	{ scope: "workflow", code: "wf-add", title: "Add Workflow", color: "btn-primary", comment: "워크플로우를 등록합니다." },
	{ scope: "account", code: "ac-addadmin", title: "Add Admin User", color: "btn-dark", comment: "관리자 계정을 추가합니다." },
	{ scope: "account", code: "ac-editadmin", title: "Edit", color: "btn-primary", comment: "관리자 계정을 수정합니다." },
	{ scope: "account", code: "ac-deladmin", title: "Delete", color: "btn-danger", comment: "관리자 계정을 삭제합니다." },
	{ scope: "account", code: "ac-editperm", title: "Apply", color: "btn-primary", comment: "계정 권한 정보를 적용합니다." },
	{ scope: "account", code: "ac-updatepw", title: "Update Password", color: "btn-primary", comment: "계정 비밀번호를 변경합니다." },
	{ scope: "paymentgateway", code: "pg-addpg", title: "Add a Payment Gateway", color: "btn-primary", comment: "Payment Gateway를 등록합니다." },
	{ scope: "paymentgateway", code: "pg-addbranch", title: "Add a PG Branch", color: "btn-primary", comment: "각 법인 별 PG를 등록 합니다." },
	{ scope: "paymentgateway", code: "pg-editinfo", title: "Edit", color: "btn-secondary", comment: "PG 정보를 수정 합니다." },
	{ scope: "supportsmanager", code: "spt-addpool", title: "Support Pool 생성", color: "btn-warning", comment: "Support에 Pool을 생성합니다." },

	/* tab */
	// { scope: "users", code: "user-info", title: "Info", color: "", comment: "사용자의 기본 정보 탭을 볼 수 있습니다." },
	{ scope: "users", code: "user-sub", title: "Subscriptions", color: "", comment: "사용자의 구독 정보 탭을 볼 수 있습니다." },
	{ scope: "users", code: "user-pay", title: "Paylogs", color: "", comment: "사용자의 결제 기록 탭을 볼 수 있습니다." },
	{ scope: "users", code: "user-manage", title: "Management", color: "", comment: "사용자의 비밀번호 변경과 회원 탈퇴 탭을 볼 수 있습니다." },
	// { scope: "groups", code: "ins-users", title: "Users", color: "", comment: "해당 기관의 사용자의 리스트 탭을 볼 수 있습니다." },
	{ scope: "groups", code: "ins-cases", title: "Cases", color: "", comment: "해당 기관의 케이스 탭을 볼 수 있습니다." },
	{ scope: "groups", code: "ins-config", title: "Config", color: "", comment: "해당 기관의 구성 요소 설정 탭을 볼 수 있습니다." },
	{ scope: "groups", code: "ins-device", title: "Device", color: "", comment: "해당 기관의 디바이스 탭을 볼 수 있습니다." },
	{ scope: "groups", code: "ins-extra", title: "Extra Info", color: "", comment: "해당 기관의 추가 정보 탭을 볼 수 있습니다." },
	// { scope: "softwares", code: "sw-info", title: "Info", color: "", comment: "해당 소프트웨어의 기본 정보 탭을 볼 수 있습니다." },
	{ scope: "softwares", code: "sw-ver", title: "Versions", color: "", comment: "해당 소프트웨어의 버전 탭을 볼 수 있습니다." },
	{ scope: "softwares", code: "sw-config", title: "Configuration", color: "", comment: "해당 소프트웨어의 구성 요소 설정 탭을 볼 수 있습니다." },
	// { scope: "managergroups", code: "mgr-users", title: "Users", color: "", comment: "Manager Group의 사용자의 리스트를 확인합니다." },
	{ scope: "managergroups", code: "mgr-license", title: "License", color: "", comment: "Manager Group의 사용자의 라이센스 탭을 볼 수 있습니다." },
	/* tab end */
]

export const SUPPORTSECTIONS = [
	{ value: "common", label: "일반적인 질문들" },
	{ value: "app", label: "소프트웨어 관련" },
	{ value: "account", label: "계정 관련" },
	{ value: "subscription", label: "구독/결제 관련" },
]

export const RMSUBTYPES = [
	{ value: "none", label: "NONE" },
	{ value: "sub-basic", label: "Basic" },
	{ value: "sub-standard", label: "Standard" },
	{ value: "sub-premium", label: "Premium" },
]

export const RMLICENSETYPE = [
	{ value : "annual", label: "Annual(credit)", desc : "사용 기간이 1년인 라이선스 입니다."},
	{ value : "normal", label : "Annual", desc : "구독결제를 통해 End-user가 사용하게 되는 라이선스 입니다." },
	{ value : "trial", label : "Trial", desc : "End-User가 시범적으로 사용'해'보도록 제공하는 라이선스 입니다." },
	{ value : "demo", label : "DEMO(Subsidiary)", desc : "법인의 영업활동을 지원하기 위해 제공하는 DEMO 라이선스로 ray 도메인 사용자 에게만 부여 할 수 있습니다." },
	{ value : "development", label : "Development", desc : "SW개발본부 혹은 임상팀에서 개발 검증을 위해 사용하도록 부여한 라이선스로 ray 도메인 사용자 에게만 부여 할 수 있습니다." },
	{ value : "kol", label: "KOL", desc : "Key Opinion Leader들에게 자사 SW의 검증을 의뢰하고, 이를 통한 Clinical Case 확보를 위해 무상으로 지원하는 라이선스 한 계정에 한번만 부여 할 수 있습니다." },
	{ value : "demodealer", label : "DEMO(Dealer)", desc : "대리점의 영업활동을 지원하기 위해 제공하는 DEMO 라이선스" },
	{ value : "commercial", label : "Monthly", desc : "구독결제를 통해 End-user가 사용하게 되는 라이선스 입니다." },
]

export const RMLICENSENAME_SUB = [
	{ value : "App-RAYFusion", label: "RAYFusion SubScription" },
	{ value : "DigitalSmileDesignInAppExportMockUp", label: "Mock-up Exporter" },
	{ value : "App-RAYDENTSplint", label: "RAYDENT Splint" },
	{ value : "App-RAYDENTDesigner", label: "RAYDent Designer" },
]

export const RMNOTY = [
	{ value: "email", label: "Email" },
	{ value: "alarm", label: "Alarm" },
]

export const LANGUAGE = [
	{ code: 'ab', label: 'Abkhazian' },
	{ code: 'aa', label: 'Afar' },
	{ code: 'af', label: 'Afrikaans' },
	{ code: 'ak', label: 'Akan' },
	{ code: 'sq', label: 'Albanian' },
	{ code: 'am', label: 'Amharic' },
	{ code: 'ar', label: 'Arabic' },
	{ code: 'an', label: 'Aragonese' },
	{ code: 'hy', label: 'Armenian' },
	{ code: 'as', label: 'Assamese' },
	{ code: 'av', label: 'Avaric' },
	{ code: 'ae', label: 'Avestan' },
	{ code: 'ay', label: 'Aymara' },
	{ code: 'az', label: 'Azerbaijani' },
	{ code: 'bm', label: 'Bambara' },
	{ code: 'ba', label: 'Bashkir' },
	{ code: 'eu', label: 'Basque' },
	{ code: 'be', label: 'Belarusian' },
	{ code: 'bn', label: 'Bengali (Bangla)' },
	{ code: 'bh', label: 'Bihari' },
	{ code: 'bi', label: 'Bislama' },
	{ code: 'bs', label: 'Bosnian' },
	{ code: 'br', label: 'Breton' },
	{ code: 'bg', label: 'Bulgarian' },
	{ code: 'my', label: 'Burmese' },
	{ code: 'ca', label: 'Catalan' },
	{ code: 'ch', label: 'Chamorro' },
	{ code: 'ce', label: 'Chechen' },
	{ code: 'ny', label: 'Chewa, Chichewa, Nyanja' },
	{ code: 'zh', label: 'Chinese' },
	{ code: 'zh-ans', label: 'Chinese (Simplified)' },
	{ code: 'zh-ant', label: 'Chinese (Traditional)' },
	{ code: 'cv', label: 'Chuvash' },
	{ code: 'kw', label: 'Cornish' },
	{ code: 'co', label: 'Corsican' },
	{ code: 'cr', label: 'Cree' },
	{ code: 'hr', label: 'Croatian' },
	{ code: 'cs', label: 'Czech' },
	{ code: 'da', label: 'Danish' },
	{ code: 'dv', label: 'Dhivehi' },
	{ code: 'nl', label: 'Dutch' },
	{ code: 'dz', label: 'Dzongkha' },
	{ code: 'en', label: 'English' },
	{ code: 'eo', label: 'Esperanto' },
	{ code: 'et', label: 'Estonian' },
	{ code: 'ee', label: 'Ewe' },
	{ code: 'fo', label: 'Faroese' },
	{ code: 'fj', label: 'Fijian' },
	{ code: 'fi', label: 'Finnish' },
	{ code: 'fr', label: 'French' },
	{ code: 'ff', label: 'Fula Pulaar Pular' },
	{ code: 'gl', label: 'Galician' },
	{ code: 'gd', label: 'Gaelic (Scottish)' },
	{ code: 'gv', label: 'Gaelic (Manx)' },
	{ code: 'ka', label: 'Georgian' },
	{ code: 'de', label: 'German' },
	{ code: 'el', label: 'Greek' },
	{ code: 'kl', label: 'Greenlandic' },
	{ code: 'gn', label: 'Guarani' },
	{ code: 'gu', label: 'Gujarati' },
	{ code: 'ht', label: 'Haitian Creole' },
	{ code: 'ha', label: 'Hausa' },
	{ code: 'he', label: 'Hebrew' },
	{ code: 'hz', label: 'Herero' },
	{ code: 'hi', label: 'Hindi' },
	{ code: 'ho', label: 'Hiri Motu' },
	{ code: 'hu', label: 'Hungarian' },
	{ code: 'is', label: 'Icelandic' },
	{ code: 'io', label: 'Ido' },
	{ code: 'ig', label: 'Igbo' },
	{ code: 'id', label: 'Indonesian in' },
	{ code: 'ia', label: 'Interlingua' },
	{ code: 'ie', label: 'Interlingue' },
	{ code: 'iu', label: 'Inuktitut' },
	{ code: 'ik', label: 'Inupiak' },
	{ code: 'ga', label: 'Irish' },
	{ code: 'it', label: 'Italian' },
	{ code: 'ja', label: 'Japanese' },
	{ code: 'jv', label: 'Javanese' },
	{ code: 'kl', label: 'Greenlandic, Kalaallisut' },
	{ code: 'kn', label: 'Kannada' },
	{ code: 'kr', label: 'Kanuri' },
	{ code: 'ks', label: 'Kashmiri' },
	{ code: 'kk', label: 'Kazakh' },
	{ code: 'km', label: 'Khmer' },
	{ code: 'ki', label: 'Kikuyu' },
	{ code: 'rw', label: 'Kinyarwanda (Rwanda)' },
	{ code: 'rn', label: 'Kirundi' },
	{ code: 'ky', label: 'Kyrgyz' },
	{ code: 'kv', label: 'Komi' },
	{ code: 'kg', label: 'Kongo' },
	{ code: 'ko', label: 'Korean' },
	{ code: 'ku', label: 'Kurdish' },
	{ code: 'kj', label: 'Kwanyama' },
	{ code: 'lo', label: 'Lao' },
	{ code: 'la', label: 'Latin' },
	{ code: 'lv', label: 'Latvian (Lettish)' },
	{ code: 'li', label: 'Limburgish ( Limburger)' },
	{ code: 'ln', label: 'Lingala' },
	{ code: 'lt', label: 'Lithuanian' },
	{ code: 'lu', label: 'Luga-Katanga' },
	{ code: 'lg', label: 'Ganda, Luganda' },
	{ code: 'lb', label: 'Luxembourgish' },
	{ code: 'gv', label: 'Manx' },
	{ code: 'mk', label: 'Macedonian' },
	{ code: 'mg', label: 'Malagasy' },
	{ code: 'ms', label: 'Malay' },
	{ code: 'ml', label: 'Malayalam' },
	{ code: 'mt', label: 'Maltese' },
	{ code: 'mi', label: 'Maori' },
	{ code: 'mr', label: 'Marathi' },
	{ code: 'mh', label: 'Marshallese' },
	{ code: 'mo', label: 'Moldavian' },
	{ code: 'mn', label: 'Mongolian' },
	{ code: 'na', label: 'Nauru' },
	{ code: 'nv', label: 'Navajo' },
	{ code: 'ng', label: 'Ndonga' },
	{ code: 'nd', label: 'Northern Ndebele' },
	{ code: 'ne', label: 'Nepali' },
	{ code: 'no', label: 'Norwegian' },
	{ code: 'nb', label: 'Norwegian bokmål' },
	{ code: 'nn', label: 'Norwegian nynorsk' },
	{ code: 'ii', label: 'Nuosu' },
	{ code: 'oc', label: 'Occitan' },
	{ code: 'oj', label: 'Ojibwe' },
	{ code: 'cu', label: 'Old Bulgarian, Old Church Slavonic' },
	{ code: 'or', label: 'Oriya' },
	{ code: 'om', label: 'Oromo (Afaan Oromo)' },
	{ code: 'os', label: 'Ossetian' },
	{ code: 'pi', label: 'Pāli' },
	{ code: 'ps', label: 'Pushto, Pashto' },
	{ code: 'fa', label: 'Persian (Farsi)' },
	{ code: 'pl', label: 'Polish' },
	{ code: 'pt', label: 'Portuguese' },
	{ code: 'pa', label: 'Punjabi (Eastern)' },
	{ code: 'qu', label: 'Quechua' },
	{ code: 'rm', label: 'Romansh' },
	{ code: 'ro', label: 'Romanian' },
	{ code: 'ru', label: 'Russian' },
	{ code: 'se', label: 'Sami' },
	{ code: 'sm', label: 'Samoan' },
	{ code: 'sg', label: 'Sango' },
	{ code: 'sa', label: 'Sanskrit' },
	{ code: 'sr', label: 'Serbian' },
	{ code: 'sh', label: 'Serbo-Croatian' },
	{ code: 'st', label: 'Sesotho' },
	{ code: 'tn', label: 'Setswana' },
	{ code: 'sn', label: 'Shona' },
	{ code: 'ii', label: 'Sichuan Yi' },
	{ code: 'sd', label: 'Sindhi' },
	{ code: 'si', label: 'Sinhalese' },
	{ code: 'ss', label: 'Siswati' },
	{ code: 'sk', label: 'Slovak' },
	{ code: 'sl', label: 'Slovenian' },
	{ code: 'so', label: 'Somali' },
	{ code: 'nr', label: 'Southern Ndebele' },
	{ code: 'es', label: 'Spanish' },
	{ code: 'su', label: 'Sundanese' },
	{ code: 'sw', label: 'Swahili (Kiswahili)' },
	{ code: 'ss', label: 'Swati' },
	{ code: 'sv', label: 'Swedish' },
	{ code: 'tl', label: 'Tagalog' },
	{ code: 'ty', label: 'Tahitian' },
	{ code: 'tg', label: 'Tajik' },
	{ code: 'ta', label: 'Tamil' },
	{ code: 'tt', label: 'Tatar' },
	{ code: 'te', label: 'Telugu' },
	{ code: 'th', label: 'Thai' },
	{ code: 'bo', label: 'Tibetan' },
	{ code: 'ti', label: 'Tigrinya' },
	{ code: 'to', label: 'Tonga' },
	{ code: 'ts', label: 'Tsonga' },
	{ code: 'tr', label: 'Turkish' },
	{ code: 'tk', label: 'Turkmen' },
	{ code: 'tw', label: 'Twi' },
	{ code: 'ug', label: 'Uyghur' },
	{ code: 'uk', label: 'Ukrainian' },
	{ code: 'ur', label: 'Urdu' },
	{ code: 'uz', label: 'Uzbek' },
	{ code: 've', label: 'Venda' },
	{ code: 'vi', label: 'Vietnamese' },
	{ code: 'vo', label: 'Volapük' },
	{ code: 'wa', label: 'Wallon' },
	{ code: 'cy', label: 'Welsh' },
	{ code: 'wo', label: 'Wolof' },
	{ code: 'fy', label: 'Western Frisian' },
	{ code: 'xh', label: 'Xhosa' },
	{ code: 'yi', label: 'Yiddish, ji' },
	{ code: 'yo', label: 'Yoruba' },
	{ code: 'za', label: 'Chuang, Zhuang' },
	{ code: 'zu', label: 'Zulu' }
];

/*
export const BOARDTYPES = [
	{ value: "HQ", label: "RAYTeams Manager Only" },
	{ value: "DEVCODES", label: "개발 연동 Template" },
	{ value: "RELEAESNOTE", label: "Software Release Note" },
	{ value: "MANUALDOC", label: "Manual Document" },
	{ value: "MANUALVIDEO", label: "Manual Video" },
	{ value : "FAQ", label : "FAQ" },
	{ value : "HOWTO", label : "MANUAL(How to)" },
	{ value : "NOTIFICATION", label: "Notification" },
	{ value : "ANNOUNCEMENTS", label: "Announcements" }
]; 
*/

export const FEEDBACKSTAUS = [
	{ value: "request", label: "Request" },
	{ value: "accept", label: "Accept" },
	{ value: "deny", label: "Deny" }
];

export const SEARCHPERIOD = [
	{ value: "all", label: "All", mcode: "years", duration: -5, dFormat: "YYYY" },
	{ value: "days", label: "Last 7 Days", mcode: "days", duration: -7, dFormat: "YYYY-MM-DD" },
	{ value: "weeks", label: "Last 14 Days", mcode: "days", duration: -14, dFormat: "YYYY-MM-DD" },
	{ value: "month", label: "Last Month", mcode: "days", duration: -30, dFormat: "YYYY-MM-DD" },
	{ value: "months", label: "Last 3 Months", mcode: "months", duration: -3, dFormat: "YYYY-MM" },
];

export const BUYTYPE = [
	{ value: "buy", label: "구매" },
	{ value: "sub", label: "구독" }
];

export const SUBTYPE = [
	{ value: "partner", label: "파트너 업체" },
	{ value: "group", label: "치과/기공소" },
	{ value: "user", label: "특정 사용자" },
	{ value: "seat", label: "특정 단말" }
];

export const SUBPERIOD = [
	{ value: "y", label: "년", elabel: "YEAR" },
	{ value: "m", label: "월", elabel: "MONTH" },
	{ value: "d", label: "일", elabel: "DAY" }
]

export const LAPPRODUCTINFOTYPE = [
	{ value: "sample", label: "Sample" },
	{ value: "default", label: "Default" }
]

export const LAPPRODUCTTYPE = [
	{ value: "orthodontics", label: "Orthodontics" },
	{ value: "prosthesis", label: "Prosthesis" }
]

export const LABPRODUCTPRICETYPE = [
	{ value: "negotiable", label: "협의 후 결정" },
	{ value: "fixed_price", label: "입력된 가격 있음" }
]

export const LABPRODUCTUSETYPE = [
	{ value: "sample", label: "Sample" },
	{ value: "default", label: "Default" },
]

export const PRICEUNIT = [
	{ value: "KRW", label: "₩", kLabel: "원" },
	{ value: "USD", label: "$", kLabel: "달러" },
	{ value: "EUR", label: "€", kLabel: "유로" },
	{ value: "JPY", label: "円", kLabel: "엔" },
	{ value: "GBP", label: "£", kLabel: "파운드" },
	{ value: "CNY", label: "¥", kLabel: "위안" },
]

export const SUBINTERVAL = [
	{ type: "PAYPAL", subType: "y", value: "YEAR" },
	{ type: "PAYPAL", subType: "m", value: "MONTH" },
	{ type: "PAYPAL", subType: "w", value: "WEEK" },
	{ type: "PAYPAL", subType: "d", value: "DAY" },
	{ type: "STRIPE", subType: "y", value: "year" },
	{ type: "STRIPE", subType: "m", value: "month" },
	{ type: "STRIPE", subType: "w", value: "week" },
	{ type: "STRIPE", subType: "d", value: "day" },
]

export const SUBSTATUS = [
	{ value: "trial", label: "평가판 구독중" },
	{ value: "normal", label: "일반 구독중" },
	{ value: "cancel", label: "구독 취소" },
	{ value: "user", label: "구독 취소 - (사용자)" },
	{ value: "manager", label: "구독 취소 - (관리자)" },
	{ value: "sub", label: "구독중" },
]

export const PGTYPE = [
	{ value: "toss", label: "Toss" },
	{ value: "paypal", label: "PayPal" },
	{ value: "stripe", label: "Stripe" }
]

export const USERTYPE = [
	{ value: "user", label: "User" },
	{ value: "manager", label: "Manager" }
]

export const USER_FILTER = [
	{ type: "COUNTRY", key: "countryCode", label: "국가", match: "EQ" },
	{ type: "GROUP", key: "groupId", label: "기관", match: "EQ" },
	{ type: "STRING", key: "partnerKey", label: "파트너", match: "CONTAINS" },
	{ type: "USERTYPE", key: "userType", label: "타입", match: "EQ" },
	{ type: "DATE", key: "created", label: "가입일", match: "EQ" },
	{ type: "DATE", key: "lastlogged", label: "최근 접속일", match: "EQ" },
]

export const GROUP_FILTER = [
	{ type: "COUNTRY", key: "countryCode", label: "국가", match: "EQ" },
	{ type: "STRING", key: "name", label: "이름", match: "CONTAINS" },
	{ type: "STRING", key: "address", label: "주소", match: "CONTAINS" },
	{ type: "GTYPE", key: "grouptype", label: "기관 타입 ", match: "IN" },
	{ type: "DATE", key: "created", label: "생성일", match: "EQ" },
	{ type: "DATE", key: "updated", label: "최근 업데이트", match: "EQ" },
]

export const PROJECT_FILTER = [
	{ type: "COUNTRY", key: "countryCode", label: "국가", match: "EQ" },
	{ type: "GROUP", key: "ownerGroupId", label: "치과", match: "EQ" },
	{ type: "GROUP", key: "labId", label: "기공소", match: "EQ" },
	{ type: "STRING", key: "partnerKey", label: "파트너", match: "CONTAINS" },
	{ type: "STRING", key: "projecttype", label: "케이스 종류", match: "CONTAINS" },
	{ type: "STRING", key: "svc", label: "연동 서비스", match: "CONTAINS" },
	{ type: "STRING", key: "svcId", label: "연동 서비스 ID", match: "CONTAINS" },
	{ type: "STRING", key: "sn", label: "장비 SN", match: "CONTAINS" },
	{ type: "NUMBER", key: "size", label: "케이스 용량", match: "EQ" },
	{ type: "NUMBER", key: "count", label: "파일 개수", match: "EQ" },
	{ type: "DATE", key: "created", label: "생성일", match: "EQ" },
	{ type: "DATE", key: "updated", label: "최근 업데이트", match: "EQ" },
]

export const DEVICE_FILTER = [
	{ type: "STRING", key: "SN", label: "RTVer ", match: "CONTAINS" },
	{ type: "STRING", key: "FwVersion", label: "국가", match: "CONTAINS" },
	{ type: "STRING", key: "HwVersion", label: "Local IP", match: "CONTAINS" },
	{ type: "STRING", key: "model", label: "Remove IP", match: "CONTAINS" },
	{ type: "STRING", key: "Version", label: "Version", match: "CONTAINS" },
	{ type: "DATE", key: "activated", label: "Activated", match: "EQ" },
	{ type: "DATE", key: "registered", label: "등록일", match: "EQ" },
	{ type: "DATE", key: "updated", label: "최근 업데이트", match: "EQ" },
]

export const DATELATEST = [
	{ value: "hour", label: "시간" },
	{ value: "day", label: "일" },
	{ value: "month", label: "개월" },
]

export const DASHBOARD_GRAPTH_TYPES = [
	{ type: "user", label: "가입자", icon: "user" },
	{ type: "case", label: "신규 케이스", icon: "folder-plus" },
	{ type: "group", label: "신규 기관", icon: "building" },
	{ type: "device", label: "설치된 디바이스", icon: "device-desktop" },
	{ type: "pay", label: "결제건수", icon: "credit-card" },
	{ type: "sub", label: "구독건수", icon: "hand-click" },
	//{ type : "amount", label : "매출액", icon : "currency-dollar" },
]

export const DASHBOARD_GRAPTH_TYPES_B2B = [
	{ type: "case", label: "신규 케이스", icon: "folder-plus" },
	{ type: "group", label: "신규 기관", icon: "building" }
	//{ type : "amount", label : "매출액", icon : "currency-dollar" },
]

export const NUMBER_COMPARE = [
	{ value: "GT", label: "보다 큼" },
	{ value: "GE", label: "보다 크거나 같음" },
	{ value: "LT", label: "보다 적음" },
	{ value: "LE", label: "보다 적거나 같음" },
	{ value: "EQ", label: "같음" },
]

export const RMLICENSETYPE_MOCKUP = [
	{ value: "normal", label: "Annual" },
	{ value: "trial", label: "Trial" },
	{ value: "demo", label: "DEMO(Subsidiary)" },
	{ value: "development", label: "Development" },
	{ value: "kol", label: "KOL" },
	{ value: "demodealer", label: "DEMO(Dealer)" }
];

export const REFUND_STATUS = [
	{ value: "request", label: "Request" },
	{ value: "done", label: "Refunded" },
	{ value: "fail", label: "Non-refundable" }
];