import axios from "axios";
import { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { history } from './history';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import { LoginPage } from './pages/auth';
import HomePage from './pages/HomePage';
import RMHeader from './components/manager/RMHeader';
import RMLeft from './components/manager/RMLeft';
import SettingPage from './pages/SettingPage';
import CogRedirect from './pages/CogRedirect';
import LicensePage from './pages/LicensePage';
import GroupPage from './pages/GroupPage';
import GroupInfoPage from './pages/GroupInfoPage';
import StatPages from './pages/StatPages';
import StatPagesV2 from './pages/StatPagesV2';
import GroupDashBoardPage from './pages/GroupDashBoardPage';
import AdminUserPage from './pages/AdminUserPage';
import AdminUserInfoPage from './pages/AdminUserInfoPage';
import AdminPwChangePage from './pages/AdminPwChangePage';
// import { axiosGlobal } from './libs/axiosConfig';
import RAYTeamsPage from './pages/RAYTeamsPage';
import RAYTeamsInfoPage from './pages/RAYTeamsInfoPage';
import BoardPage from './pages/BoardPage';
import BoardInfoPage from './pages/BoardInfoPage';
import ContentInfoPage from './pages/ContentInfoPage';
import UserContentAddPage from './pages/UserContentAddPage';
import UserContentPage from './pages/UserContentPage';
import RMToolbar from './components/manager/RMToolbar';
import RMFooter from './components/manager/RMFooter';
import LicenseAppListPage from './pages/licenses/LicenseAppListPage';

import NotificationList from "./pages/notifications/NotificationList";
import NotificationInfo from './pages/notifications/NotificationInfo';

import UsagePage from './pages/usage/UsagePage';
import UsageInfoPage from './pages/usage/UsageInfoPage';
import ProductPage from './pages/ProductPage';
import ProductAdd from './components/product/RMProductAdd';
import ProductInfoPage from './pages/ProductInfoPage';
import ProductDetailPage from './pages/ProductDetailPage';
import WorkflowPage from './pages/WorkflowPage';
import WorkflowInfoPage from './pages/WorkflowInfoPage';
import WorkflowAddPage from './pages/WorkflowAddPage';
import RayteamsRegionPage from './pages/rayteams/RayteamsRegionPage';

import UsersPage from './pages/users/UsersPage';
import UsersDashBoardPage from './pages/users/UsersDashBoardPage';
import UsersInfoPage from './pages/users/UsersInfoPage';
import ProjectsPage from './pages/projects/ProjectsPage';
import ProjectsDashBoardPage from './pages/projects/ProjectsDashBoardPage';
import ProjectsInfoPage from './pages/projects/ProjectsInfoPage';
import FeedBacksPage from './pages/feedbacks/FeedBacksPage';
import FeedBacksInfoPage from './pages/feedbacks/FeedBacksInfoPage';

import ManagerFeedbacks from './pages/managerfeedbacks/ManagerFeedbacks';

import PartnerInfoPage from './pages/partner/PartnerInfoPage';
import PartnersPage from './pages/partner/PartnersPage';
import PartnerAddPage from './pages/partner/PartnerAddPage';
import PartnerDashBoardPage from './pages/partner/PartnerDashBoardPage';

/* Payments/Subsciptions */
import PaymentsPage from './pages/payments/Payments';
import SubscriptionsPage from './pages/subscriptions/Subscriptions';
import SubscriptionsDashboard from './pages/subscriptions/SubscriptionsDashboard';

import PaymentGatewayPage from './pages/paymentgateway/PaymentGatewayPage';
import PaymentGatewayInfoPage from './pages/paymentgateway/PaymentGatewayInfoPage';

import DevicesPage from './pages/devices/DevicesPage';
import DeviceInfoPage from './pages/devices/DeviceInfoPage';

import ApplicationsPage from './pages/applications/ApplicationsPage';
import ApplicationInfoPage from './pages/applications/ApplicationInfoPage';

import Rayteams from './pages/rayteams/RayteamsPage';

import { AlertConstant, AuthConstant, PaymentConstant, ProgramConstant } from './redux/reducers';

import LanguagePage from './pages/common/LanguagePage';
import LangPage from './pages/common/LangPage';

import MLToast from './components/common/MLToast';

import RTProductPage from './pages/rtproduct/RTProductPage';
import RTProductInfoPage from './pages/rtproduct/RTProductInfoPage';
import RTProductAddPage from './pages/rtproduct/RTProductAddPage';

import ManagerGroupPage from './pages/managergroup/ManagerGroupPage';
import ManagerGroupAddPage from './pages/managergroup/ManagerGroupAddPage';
import ManagerGroupInfoPage from './pages/managergroup/ManagerGroupInfoPage';
import { AppConstant } from './redux/reducers/AppReducer';
import { fetchData } from './libs/db';
import { MLButton } from './components/common/MLButton';
import { COLORS } from './constants/FromInputs';
import ModalPayInfo from './components/payments/ModalPayInfo';

/* Notification */
import NotifyEmailPage from './pages/notifications/notifyemail';
import RTFormsPage from "./pages/forms/RTFormsPage";
import RTFormsAdd from "./pages/forms/RTFormsAddPage";
import CodeConfigPage from "./pages/code/CodeConfigPage";
import LanguageConfigPage from "./pages/lang/LanguageConfigPage";
import LabProductAddPage from "./pages/labproduct/LabProductAddPage";
import LabProductPage from "./pages/labproduct/LabProductPage";
import LabProductInfoPage from "./pages/labproduct/LabProductInfoPage";

import LabProductCategoryAddPage from "./pages/labproduct/LabProductCategoryAddPage";
import LabProductCategoryPage from "./pages/labproduct/LabProductCategoryPage";
import LabProductCategoryInfoPage from "./pages/labproduct/LabProductCategoryInfoPage";

import { RTAudit } from "./libs/audit";
import AuditPage from "./pages/audit/Audit";
import MailingPage from "./pages/mailing/MailingPage";
import MailingSendPage from "./pages/mailing/MailingSendPage";
import SiteConfigPage from "./pages/siteconfig/SiteConfigPage";
import { GetCodeConfig, GetConfig } from "./libs/appConfig";
import { Helmet } from "react-helmet";
import ManagerUserInfoPage from "./pages/managerusers/ManagerUserInfoPage";
import { InfoAction } from "./redux/actions";
import AdminPwSecurity from "./pages/AdminPwSecurity";

const App = ({ config, loginresult, admintype, pwchange, usersub, regions, loginuser, modalPayData, allgroups }) => {

	const dispatch = useDispatch();
	const [waiting, setWaiting] = useState(false);
	const swUpdate = useSelector(state => state.AppReducer.swUpdate);

	useEffect(() => {

		if (loginresult && localStorage.getItem("pwChangeFlag")) {
			history.push(localStorage.getItem("pwChangeFlag"))
		}

		if (loginresult && localStorage.getItem("pwchange") == "no" && !!localStorage.getItem("usersub")) {
			history.push("/AdminPwChange/" + localStorage.getItem("usersub"));
		} else {
			loginresult ? "" : history.push("/Login");
		}
		if (loginresult) {
			axiosGlobal();
			getData_dGroups();
		}
	}, [loginresult]);

	useEffect(() => {
		if (!allgroups) {
			getAllGroups();
		}
	}, [allgroups])

	useEffect(() => {
		if (!loginuser?.menurole && localStorage.getItem("usersub")) {
			getData_UserRole(localStorage.getItem("usersub"));
		}
	}, [loginuser])

	const getData_dGroups = async () => {
		const ScanFilter = {
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"],
			},
			hasMember: {
				ComparisonOperator: "EQ",
				AttributeValueList: [true],
			},
		}
		try {
			const ret = await fetchData("rayteams-group", ScanFilter);
			// localStorage.setItem("groups", JSON.stringify(ret.Items));
			dispatch({ type: AuthConstant.GET_ALL_GROUPS, items: ret.Items });
		} catch (e) {
			dispatch({ type: AlertConstant.ERROR, msg: e.message, show: true });
			dispatch({ type: AuthConstant.GET_ALL_GROUPS, items: [] });
		}
	}

	const serviceFavicon = useMemo(() => {
		const _fav = config?.servicefavicon;
		if (typeof _fav !== "string") {
			return "/favicon.ico";
		}
		return _fav?.trim() ? _fav?.trim() : "/favicon.ico";
	}, [config]);

	const getAllGroups = async () => {
		let ret = await InfoAction.callDB({
			type: "scan", region: "ap-northeast-2", params: {
				TableName: "rayteams-group",
				FilterExpression: "#sk=:sk",
				ExpressionAttributeNames: { "#sk": "sk", "#id": "_id", "#name": "name", "#grouptype": "grouptype", "#region": "region" },
				ExpressionAttributeValues: { ":sk": "info" },
				ProjectionExpression: "#sk, #id, countryCode, #name, #grouptype, #region"
			}
		});
		dispatch({ type: AuthConstant.GET_ALL_GROUPS_TOAL, items: ret })
	}
	const getData_UserRole = async (usersub) => {
		const ScanFilter = {
			_id: {
				ComparisonOperator: "EQ",
				AttributeValueList: [usersub]
			},
			sk: {
				ComparisonOperator: "EQ",
				AttributeValueList: ["info"]
			}
		}
		const ret = await fetchData("rayteams-manager-user", ScanFilter);
		dispatch({
			type: AuthConstant.GET_LOGINUSER_ROLE, items: {
				menu: ret.Items[0]?.menu || [],
				perms: ret.Items[0]?.perms || []
			}
		})
	}

	const logout = () => {
		localStorage.clear();
		console.log('logout');
		dispatch({ type: AuthConstant.LOGOUT });
	}

	const axiosGlobal = () => {
		axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				if (error.response?.status === 401) {
					logout();
				} else {
					//logout();
				}
				return Promise.reject(error);
			}
		);
	}

	useEffect(() => {
		axiosGlobal();
		setConfig();
	}, []);

	const setConfig = async () => {
		dispatch({ type: ProgramConstant.SET_CONFIG, items: await GetCodeConfig() })
		dispatch({ type: ProgramConstant.SET_SERVICE, item: await GetConfig() })
	}

	const simple = window.location.href.indexOf("AdminPwChange") > -1;

	// console.log("loginresult : ", loginresult)

	return (<>
		<Helmet>
			<meta charSet='utf-8' />
			<link id='favicon' rel="shortcut icon" href={serviceFavicon} type="image/x-icon" />
			<title>{config?.rayteamstitle || `RAYTeams`}</title>
		</Helmet>
		<Router history={history}>
			{loginresult && <div className="layout-wrapper layout-content-navbar">
				<div className='layout-container'>
					{!simple && <RMLeft />}

					<div className={'layout-page'}>
						{/* <RMHeader /> */}
						{!simple && <RMToolbar />}
						<div className='content-wrapper'>
							<Switch>
								<AuthRoute path="/UsersInfo/:_id/:tab?" component={UsersInfoPage} />
								<AuthRoute path="/CogRedirect" component={CogRedirect} />
								<AuthRoute path="/Stats" component={StatPages} />
								<AuthRoute path="/StatsV2" component={StatPagesV2} />
								<AuthRoute path="/Setting" component={SettingPage} />
								<AuthRoute path="/UsagePage" component={UsagePage} />
								<AuthRoute path="/UsageInfoPage/:_app/:_type/:_period" component={UsageInfoPage} />
								<AuthRoute path="/RAYTeamsPage/:tab?" component={RAYTeamsPage} />
								<AuthRoute path="/RAYTeamsInfo/:_id" component={RAYTeamsInfoPage} />
								<AuthRoute path="/RayteamsRegionPage/:tab?" component={RayteamsRegionPage} />

								{/* Users */}
								<AuthRoute path="/UsersPage" component={UsersPage} />
								<AuthRoute path="/UsersDashBoardPage" component={UsersDashBoardPage} />

								{/* Cases */}
								<AuthRoute path="/ProjectsPage" component={ProjectsPage} />
								<AuthRoute path="/ProjectsInfo/:_id/:tab?" component={ProjectsInfoPage} />
								<AuthRoute path="/ProjectsDashBoardPage" component={ProjectsDashBoardPage} />

								{/* Lab / Clinic */}
								<AuthRoute path="/GroupPage" component={GroupPage} />
								<AuthRoute path="/GroupInfo/:_id/:tab?" component={GroupInfoPage} />
								<AuthRoute path="/GroupDashBoard" component={GroupDashBoardPage} />

								{/* Devices */}
								<AuthRoute path="/Devices" component={DevicesPage} />
								<AuthRoute path="/Device/:_id" component={DeviceInfoPage} />

								{/* RAYTeams Client */}
								<AuthRoute path="/Rayteams" component={Rayteams} />

								{/* Softwares */}
								<AuthRoute path="/Applications" component={ApplicationsPage} />
								<AuthRoute path="/Application/:_id/:tab?" component={ApplicationInfoPage} />

								{/* Licenses */}
								<AuthRoute path="/LicensePage" component={LicensePage} />
								<AuthRoute path="/LicenseAppList/:_id" component={LicenseAppListPage} />

								{/* B2B Lab Product */}
								<AuthRoute path="/LabProductPage" component={LabProductPage} />
								<AuthRoute path="/LabProductInfoPage/:_id?" component={LabProductInfoPage} />
								<AuthRoute path="/LabProductAddPage/:_id?" component={LabProductAddPage} />

								{/* B2B Lab Product Category */}
								<AuthRoute path="/LabProductCategoryPage" component={LabProductCategoryPage} />
								<AuthRoute path="/LabProductCategoryInfoPage/:_id?" component={LabProductCategoryInfoPage} />
								<AuthRoute path="/LabProductCategoryAddPage/:_id?" component={LabProductCategoryAddPage} />

								{/* Lab Product */}
								<AuthRoute path="/ProductPage" component={ProductPage} />
								<AuthRoute path="/Product/:_id" component={ProductInfoPage} />
								<AuthRoute path="/ProductAdd" component={ProductAdd} />
								<AuthRoute path="/ProductEdit/:_id" component={ProductAdd} />
								<AuthRoute path="/ProductDetail/:_pid/:_cid" component={ProductDetailPage} />

								{/* Workflow */}
								<AuthRoute path="/WorkflowPage" component={WorkflowPage} />
								<AuthRoute path="/Workflow/:_wid" component={WorkflowInfoPage} />
								<AuthRoute path="/WorkflowAdd" component={WorkflowAddPage} />

								{/* Partner */}
								<AuthRoute path="/Partners" component={PartnersPage} />
								<AuthRoute path="/Partner/:_id/:_tab?" component={PartnerInfoPage} />
								<AuthRoute path="/PartnerAdd" component={PartnerAddPage} />
								<AuthRoute path="/PartnerEdit/:_id" component={PartnerAddPage} />
								<AuthRoute path="/PartnerDashBoard" component={PartnerDashBoardPage} />

								{/* RAYTeams Product (Beta) */}
								<AuthRoute path="/RTProduct" component={RTProductPage} />
								<AuthRoute path="/RTProductAdd" component={RTProductAddPage} />
								<AuthRoute path="/RTProductAddEdit/:_id" component={RTProductAddPage} />
								<AuthRoute path="/RTProductInfo/:_id/:tab?" component={RTProductInfoPage} />

								{/* RAYTeams Product (Beta) */}
								<AuthRoute path="/Forms" component={RTFormsPage} />
								<AuthRoute path="/FormsAdd" component={RTFormsAdd} />
								<AuthRoute path="/FormInfo/:_id" component={RTFormsAdd} />


								<AuthRoute path="/Codes" component={CodeConfigPage} />
								<AuthRoute path="/Lang" component={LanguagePage} />
								<AuthRoute path="/lang2" component={LangPage} />

								{/* Payments/Subscriptions */}
								<AuthRoute path="/Payments" component={PaymentsPage} />
								<AuthRoute path="/Subscriptions" component={SubscriptionsPage} />
								<AuthRoute path="/SubscriptionsDashboard" component={SubscriptionsDashboard} />

								{/* Payment Gateway */}
								<AuthRoute path="/PaymentGateways" component={PaymentGatewayPage} />
								<AuthRoute path="/PaymentGateway/:_id" component={PaymentGatewayInfoPage} />

								{/* Language */}
								{/*
								<AuthRoute path="/Language" component={LanerConuagePage} />
                                */}

								{/* User FeedBacks */}
								<AuthRoute path="/FeedBacksPage" component={FeedBacksPage} />
								<AuthRoute path="/FeedBacksInfo/:_id" component={FeedBacksInfoPage} />

								{/* ManagerFeedbacks */}
								<AuthRoute path="/ManagerFeedbacks" component={ManagerFeedbacks} />

								{/* Manager Group */}
								<AuthRoute path="/ManagerGroup" component={ManagerGroupPage} />
								<AuthRoute path="/ManagerGroupAdd" component={ManagerGroupAddPage} />
								<AuthRoute path="/ManagerGroupInfo/:_id/:tab?" component={ManagerGroupInfoPage} />
								<AuthRoute path="/ManagerGroupEdit/:_id" component={ManagerGroupAddPage} />

								{/* Community */}
								<AuthRoute path="/BoardPage" component={BoardPage} />
								<AuthRoute path="/Board/:_bid/:_tab?" component={BoardInfoPage} />
								<AuthRoute path="/Content/:_bid/:_cid/:_sk?" component={ContentInfoPage} />
								{/*<AuthRoute path="/Content/:_cid/:_sk?" component={UserContentPage} />*/}
								<AuthRoute path="/UserContent/:_cid/:_sk?" component={UserContentPage} />
								<AuthRoute path="/UserContentAdd/:_bid/:_cid?/:_sk?" component={UserContentAddPage} />

								<AuthRoute path="/Notifications" component={NotificationList} />
								<AuthRoute path="/NotificationsInfo/:_cid" component={NotificationInfo} />

								{/* HQ Users */}
								<AuthRoute path="/AdminUserPage" component={AdminUserPage} />
								<AuthRoute path="/AdminUserInfo/:_id" component={AdminUserInfoPage} />
								<AuthRoute path="/AdminPwChange/:_id" component={AdminPwChangePage} />
								<AuthRoute path="/ManagerUserInfo/:_id" component={ManagerUserInfoPage} />

								<AuthRoute path="/Logs" component={AuditPage} />
								<AuthRoute path="/MailingPage" component={MailingPage} />
								<AuthRoute path="/MailingSendPage" component={MailingSendPage} />
								<AuthRoute path="/security" component={AdminPwSecurity} />


								{/* Notification */}
								{/* <AuthRoute path="/NotifyEmail" component={NotifyEmailPage} /> */}

								<AuthRoute path="/SiteConfigPage/:_tab?" component={SiteConfigPage} />

								<AuthRoute path="/" component={HomePage} />
							</Switch>
							{!simple && !process.env.REACT_APP_COMPANY && <RMFooter />}
						</div>
						<ModalPayInfo
							modalData={modalPayData}
							paylog={modalPayData?.paylog}
							CloseModal={() =>
								dispatch({ type: PaymentConstant.PAY_LOG_MODAL, item: { ...modalPayData, show: false } })
							} />
					</div>
				</div>
			</div>}
			{(!loginresult || (loginresult && simple)) &&  <div className='container-xxl'>
				<div className='authentication-wrapper authentication-basic container-p-y'>
					<div className='authentication-inner py-4'>
						<Switch>
							<Route exact path="/Login" component={LoginPage} />
							<Route path="/AdminPwChange/:_id" component={AdminPwChangePage} />
						</Switch>
					</div>
				</div>
			</div>}
			<MLToast pos={"bottom-center"} />
		</Router>
	</>);
}

const mapState = (state) => {
	const user = state.AuthReducer.user;
	const authGroup = state.AuthReducer.group;
	const loginresult = state.AuthReducer.loginresult;
	const userToken = state.AuthReducer.userToken;
	const admintype = state.AuthReducer.admintype;
	const pwchange = state.AuthReducer.pwchange;
	const allgroups = state.AuthReducer.allgroups;
	const usersub = state.AuthReducer.usersub;
	const regions = state.AuthReducer.regions
	const regionlist = state.AuthReducer.regionlist
	const loginuser = state.AuthReducer.loginuser;
	const modalPayData = state.PaymentReducer.modalPayData;
	const config = state.ProgramReducer.config;

	return { user, authGroup, loginresult, userToken, admintype, pwchange, usersub, regions, regionlist, loginuser, modalPayData, config, allgroups };
};

export default connect(mapState, null)(App);
